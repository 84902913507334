<template>
  <div>
    <template v-if="isAdmin">
      <el-message type="warning" class="mb-16 text-yellow-700 text-sm cursor-pointer whitespace-no-wrap">
        <div>
          Pending approval! <span class="underline font-medium hover:text-yellow-600 ml-4 whitespace-normal">You need to approve 4 products before they can go online</span>
        </div>
      </el-message>

      <h1 class="mb-20 font-medium text-lg whitespace-no-wrap">Sales report</h1>

      <div class="grid grid-cols-1 gap-20 sm:grid-cols-3">
        <el-card class="px-16 sm:px-24 py-16">
          <p class="text-grey-400">Pending payment</p>
          <p class="text-2xl font-medium leading-bigger mt-4">kr. 45.343 <span class="text-grey-300 text-sm">excl. vat</span></p>
        </el-card>

        <el-card class="px-16 sm:px-24 py-16">
          <p class="text-grey-400">Last payment</p>
          <p class="text-2xl font-medium leading-bigger mt-4">kr. 35.213 <span class="text-grey-300 text-sm">excl. vat</span></p>
        </el-card>

        <el-card class="px-16 sm:px-24 py-16">
          <p class="text-grey-400">Total payment</p>
          <p class="text-2xl font-medium leading-bigger mt-4">kr. 124.564 <span class="text-grey-300 text-sm">excl. vat</span></p>
        </el-card>
      </div>

      <search class="mt-16 mb-20" />

      <div class="flex flex-col sm:flex-row sm:items-center justify-between mb-20">
        <h1 class="font-medium text-lg whitespace-no-wrap">Best selling products Open help</h1>

        <div class="flex flex-wrap mt-12 sm:mt-0 sm:ml-16">
          <el-button tag="button">Open help</el-button>
          <el-button tag="button" type="primary" class="ml-12">Manage inventory</el-button>
        </div>
      </div>

      <dashboard-list />
    </template>

    <template v-else>
      <h1 class="font-medium text-lg sm:mb-0 whitespace-no-wrap text-center">Welcome to Paperboy</h1>
    </template>
  </div>
</template>

<script>
import { enums } from '@/core/enums'
import { mapActions, mapState } from 'vuex'

import ElCard from '@/components/shared/ELCard'
import ElButton from '@/components/shared/ELButton'
import ElMessage from '@/components/shared/ELMessage'
import Search from '@/components/dashboard/Search'
import DashboardList from '@/components/dashboard/DashboardList'

export default {
  name: 'DashboardPage',

  components: { ElCard, ElButton, ElMessage, Search, DashboardList },

  computed: {
    ...mapState({
      userData: state => state.user.userData
    }),

    isAdmin () {
      return this.userData && (this.userData.role === enums.ROLES.ADMIN)
    }
  },

  created () {
    this.loading(false)
  },

  methods: {
    ...mapActions(['loading'])
  }
}
</script>
