<template>
  <el-card class="sm:px-0">
    <ul v-if="productsList.length > 0" class="-my-20">
      <li
        v-for="(product, idx) in productsList"
        :key="idx"
        class="bg-white hover:bg-grey-50 py-16 px-16 sm:px-24 border-b border-transparent cursor-pointer"
        :class="{ 'border-hr': idx !== productsList.length - 1 }"
        @click="onRowClick(product)"
      >
        <div class="flex flex-1 items-center justify-between mb-8">
          <p class="text-green-500 font-medium">{{ product.title }}</p>

          <el-status status="Open" class="normal-case">No. {{ product.number }}</el-status>
        </div>

        <div class="sm:flex sm:items-center sm:justify-between">
          <div class="flex items-center mr-24 mb-8 sm:mb-0">
            <span class="icon-sold flex justify-start text-2lg text-grey-300" />
            <p class="ml-6 text-grey-400">Sold status: {{ product.sold }} sold</p>
          </div>

          <div class="flex items-center ml-32 sm:ml-0">
            <span class="icon-money flex justify-start text-2lg text-grey-300" />
            <span class="ml-6 inline-block text-grey-400">Total earnings: {{ product.earnings }}</span>
          </div>
        </div>
      </li>
    </ul>
  </el-card>
</template>

<script>
const ElCard = () => import('@/components/shared/ELCard')
const ElStatus = () => import('@/components/shared/ELStatus')

export default {
  name: 'DashboardList',

  components: { ElCard, ElStatus },

  props: {
    isRowClick: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      productsList: [
        {
          title: 'Product name - variant name',
          sold: 43,
          earnings: 23693,
          number: 1
        },
        {
          title: 'Product name - variant name',
          sold: 43,
          earnings: 23693,
          number: 2
        }
      ]
    }
  },

  methods: {
    onRowClick (product) {}
  }
}
</script>
