<template>
  <el-card class="px-16 sm:px-24 py-16">
    <p class="mb-48 text-lg font-medium leading-big">Search for products, sku, orders, booking reference</p>

    <div class="flex flex-col sm:flex-row sm:items-center">
      <el-input v-model="search" placeholder="Search here" input-class="rounded" class="sm:max-w-xs w-full" />
      <el-button tag="button" type="primary" class="mt-12 sm:mt-0 sm:ml-12">Search</el-button>
    </div>
  </el-card>
</template>

<script>
import ElCard from '@/components/shared/ELCard'
import ElInput from '@/components/shared/ELInput'
import ElButton from '@/components/shared/ELButton'

export default {
  name: 'DashboardSearch',

  components: { ElCard, ElInput, ElButton },

  data () {
    return {
      search: ''
    }
  }
}
</script>
