<template>
  <div :class="[type, 'p-16']">
    <div class="flex">
      <span v-if="isWarning" class="icon-warning flex-inline justify-start text-2lg text-yellow-400 mr-12" />
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ElMessage',

  props: {
    // success, warning, error
    type: { type: String, default: 'warning' }
  },

  computed: {
    isWarning () {
      return this.type === 'warning'
    }
  }
}
</script>

<style lang="scss" scoped>
  .warning {
    @apply bg-yellow-50 border-l-4 border-yellow-400;
  }
</style>
